import BannerImage from '../assets/banner.png';
import BannerImageMobile from '../assets/hero_image_mobile.png';
import BannerImageTablet from '../assets/hero_image_tablet.png';
import BannerImageDesktop from '../assets/hero_image_desktop.png';
import AndSvg from '../assets/and.svg';

function HeroBanner() {
  return (
    <div>
      <div className="relative">
        <img className="w-full h-full object-cover md:hidden" src={BannerImageMobile} alt="Hero Banner" />
        <img className="w-full h-full object-cover max-md:hidden lg:hidden" src={BannerImageTablet} alt="Hero Banner" />
        <img className="w-full h-full object-cover max-lg:hidden xl:hidden" src={BannerImageDesktop} alt="Hero Banner" />
        <img className="w-full h-full object-cover max-xl:hidden" src={BannerImage} alt="Hero Banner" />
        <div className='absolute top-0 left-0 w-full h-full'>
          <div className='flex flex-row justify-between items-start max-w-[320px] mx-auto mt-[10vw]
              md:flex-col md:ml-[10vw] md:mt-[20vw] md:max-w-[290px]
              lg:max-w-[360px]
              xl:mt-[7vw] xl:ml-[15vw]
              2xl:max-w-[450px] 2xl:mt-[10vw]
              3xl:max-w-[520px] 3xl:mt-[15vw] 3xl:ml-[20vw]
              
          '>
            <h1 className='text-[24px] md:text-[43px] lg:text-[50px] 2xl:text-[68px] uppercase text-charcoal-500 leading-[1.2]'>
              <div>
                Thiệp mời
              </div>
              <div>
                Đám Cưới
              </div>
            </h1>

            <div className="text-blush-900 text-lg md:text-[30px] lg:text-[35px] 2xl:text-[48px] md:mt-[10vw] 2xl:mt-[5vw]">
              <div className='font-montecarlo leading-[1.5]  whitespace-nowrap'>
                <div>
                  Nguyễn Phan Bảo Ngọc
                </div>
                <div className='flex items-center  whitespace-nowrap'>
                  <img className='size-[2vw] ml-[4vw] mr-[2vw]' src={AndSvg} alt="And" />
                  <span>
                    Phan Thanh Triều
                  </span>
                </div>
              </div>

              <div className='mt-[2vw] text-[11px] lg:text-[14px] 2xl:text-[18px] max-md:hidden leading-[1.5]'>
                Hân hạnh thông báo ngày lễ trọng đại của chúng mình và trân trọng kính mời quý vị đến tham dự, chung vui cùng gia đình hai họ.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='my-8 text-xs md:hidden max-w-[270px] mx-auto text-center text-charcoal-500'>
        Hân hạnh thông báo ngày lễ trọng đại của chúng mình và trân trọng kính mời quý vị đến tham dự, chung vui cùng gia đình hai họ.
      </div>
    </div>
  );
}

export default HeroBanner;

