import TopLeftImage from '../assets/topleft-string.svg';
import BottomRightImage from '../assets/bottomright-string.svg';

export default function SecondInfoSection() {
    return (
        <div className="container container--sm py-4 md:py-10">
            <div className="relative py-10 md:py-16 pb-20 md:pb-24">
                <img src={TopLeftImage} alt="Top Left Image" className="absolute top-0 left-0 w-[102px] md:w-[182px]" />
                <img src={BottomRightImage} alt="Bottom Right Image" className="absolute bottom-0 right-0 w-[132px] md:w-[202px]" />
                <div className='flex flex-col gap-4 items-center text-center text-charcoal-500'>
                    <div className='text-2xl md:text-4xl text-blush-800 font-semibold'>
                        LỄ VU QUY
                    </div>
                    <div className='text-lg md:text-xl'>
                        vào lúc
                    </div>
                    <div className='border-t border-b border-charcoal-500 py-3 text-2xl md:text-3xl font-semibold tracking-wider'>
                        <div>
                            07h00 Chủ Nhật
                        </div>
                        <div>
                            Ngày 05/01/2025
                        </div>
                    </div>
                    <div className="text-lg mt-6 italic md:text-xl">
                        (nhằm ngày 06/12/2024 âm lịch)
                    </div>
                </div>
            </div>
        </div>
    )
}
