import { FaMapMarkerAlt } from "react-icons/fa";
import Button from "../elements/Button";

export default function InfoSection() {
    const link = 'https://www.google.com/maps/search/10.163200,+105.921694?entry=tts&g_ep=EgoyMDI0MTIwNC4wIPu8ASoASAFQAw%3D%3D';

    return (
        <div className="bg-gold-50 my-10">
            <div className='container py-10 md:py-16'>
                <div className="flex flex-col items-center gap-2 text-center text-charcoal-500 md:gap-3">
                    <div className="text-2xl font-montecarlo md:text-4xl">
                        Trân trọng kính mời quý khách
                    </div>
                    <div className="text-sm md:text-lg">
                        ĐẾN DỰ BUỔI TIỆC CHUNG VUI TẠI NHÀ RIÊNG
                    </div>
                    <div className="text-xs italic my-2 md:text-base">
                        Địa chỉ
                    </div>
                    <div className="font-semibold italic mb-3 md:text-lg">
                        130/12 ấp Phước Yên A, Phú Quới, Long Hồ, Vĩnh Long
                    </div>

                    <Button link={link} target="_blank" icon={<FaMapMarkerAlt />}>
                        Chỉ đường
                    </Button>
                    <div className="text-lg mt-4 md:text-xl">
                        TỔ CHỨC VÀO
                    </div>

                    <div className="mt-2 flex items-center gap-6">
                        <div className="border-charcoal-500 border-t border-b py-3 text-2xl md:text-3xl uppercase tracking-wider">
                            Thứ BẢY
                        </div>
                        <div className="text-5xl font-bold tracking-widest md:text-6xl">
                            04
                        </div>
                        <div className="border-charcoal-500 border-t border-b py-3 text-2xl uppercase md:text-3xl">
                            Tháng 01
                        </div>
                    </div>
                    <div className="text-2xl md:text-3xl">
                        2025
                    </div>
                    <div className="text-lg mt-6 italic md:text-xl">
                        (nhằm ngày 05/12/2024 âm lịch)
                    </div>
                </div>
            </div>
        </div>

    )
}
