import Image1 from '../assets/bottom1.png';
import Image2 from '../assets/bottom2.png';
import Image3 from '../assets/bottom3.png';
import Image4 from '../assets/bottom4.png';
import Image5 from '../assets/bottom5.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import BottomStringImage from '../assets/bottom-string.svg';
import CDBlackImage from '../assets/cd-black.svg';
import { motion } from 'framer-motion';
import { useRef, useEffect, useState, useMemo } from 'react';


const images = [
    {
        image: Image1,
        title: 'Ngày cầu hôn',
    },
    {
        image: Image2,
        title: 'Sinh nhật đầu tiên cùng nhau',
    },
    {
        image: Image3,
        title: 'Chuyến đi đầu tiên cùng nhau',
    },
    {
        image: Image4,
        title: 'Chuyến đi đầu tiên cùng nhau',
    },
    {
        image: Image5,
        title: 'Ngày chung đôi',
    }
];


export default function BottomSwiper() {
    return (
        <div className="mt-10 md:mt-16">
            <div className="text-center text-2xl md:text-4xl font-montecarlo">
                Our
            </div>
            <div className="text-center text-2xl md:text-4xl uppercase mt-2 md:mt-4 mb-4 md:mb-6">
                LOVE STORY
            </div>

            <Swiper
                modules={[Autoplay, Pagination, Navigation]}
                breakpoints={{
                    375: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 60,
                    }
                }}
                autoplay={{
                    delay: 0,
                    disableOnInteraction: false,
                }}
                speed={3000}
                loop={true}
                centeredSlides={true}
            >
                {images.map((item, index) => (
                    <SwiperSlide key={index}>
                        <BottomImage image={item.image} title={item.title} />
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className='flex flex-col items-center gap-2 md:gap-8 mt-10 md:mt-16'>
                <div className='font-montecarlo text-3xl md:text-5xl'>
                    Bảo Ngọc và Thanh Triều
                </div>
                <div className='text-lg md:text-2xl'>
                    Rất hân hạnh được đón tiếp!
                </div>
                <img src={BottomStringImage} alt="Bottom String" className='w-[200px] md:w-[250px] h-auto' />
            </div>

            <div className='mt-[150px] md:mt-[250px] flex items-center justify-center gap-2'>
                <div>
                    Design & Develop by
                </div>
                <strong>
                    Trường An & Gia Khôi
                </strong>
            </div>
        </div>
    );
}

function BottomImage({ image, title }) {
    const ref = useRef(null);

    const imageRef = useRef(null);
    const [active, setActive] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        ref.current.addEventListener('mouseenter', () => {
            setActive(true);
        });
        ref.current.addEventListener('mouseleave', () => {
            setActive(false);
        });

        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth);
        });

        if (window.navigator.userAgent.includes('iPhone')) {
            setIsIOS(true);
        }
    }, []);
    

    const isActive = useMemo(() => {
        if (windowWidth < 768) {
            return true;
        }
        return active;
    }, [active, windowWidth])

    return (
        <div ref={ref} className='relative'>
            <motion.div
                animate={isActive ? {
                    x: '27%'
                } : {
                    x: 0
                }}
                className='z-20 w-[60%] mx-auto relative aspect-[1,1]'>
                <div className={`absolute right-0 top-0 z-[-2] w-full h-full`}>
                    <motion.div
                        animate={isActive ? {
                            x: '-60%'
                        } : {
                            x: 0
                        }}
                        transition={{
                            type: 'spring',
                            stiffness: 100,
                            damping: 10,
                            mass: 1,
                        }}
                        className='w-full h-full'
                    >
                        <div className='w-full h-full p-4'>
                            <motion.div
                                animate={{
                                    rotate: 360
                                }}
                                transition={{
                                    duration: 10,
                                    ease: "linear",
                                    repeat: Infinity,
                                    repeatType: "loop"
                                }}
                                className='relative w-full h-full'>
                                <img src={CDBlackImage} alt="CD Black" className='h-full w-auto' />
                                <img src={image} alt="main image" className='size-[40%] object-cover aspect-[1,1] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full' />
                            </motion.div>
                        </div>
                    </motion.div>
                </div>
                <div className='h-full w-[40px] absolute right-[calc(100%-1px)] top-0 z-[-1]'>
                    <motion.div
                        animate={isActive ? {
                            x: 0
                        } : {
                            x: '100%'
                        }}
                        transition={{
                            type: 'spring',
                            stiffness: 100,
                            damping: 10,
                            mass: 1,
                        }}
                        className='relative w-full h-full bg-gold-200'>
                        <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                             rotate-[-90deg] text-2xl font-whitegone whitespace-nowrap ml-1'>
                            {title}
                        </div>
                    </motion.div>
                </div>
                <img ref={imageRef} className='w-full h-full object-cover aspect-[1,1] z-20' src={image} alt={`Image ${title}`} />
            </motion.div>
        </div>
    )
}

