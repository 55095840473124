import Button from "../elements/Button";
import { FaMapMarkerAlt } from "react-icons/fa";
import Map from '../assets/baixe.png'
import { MdOutlineSaveAlt } from "react-icons/md";


export default function HowToSection() {
    const link1 = 'https://maps.app.goo.gl/7QStUqArZrB1dhT86';
    const link2 = 'https://maps.app.goo.gl/du2h12c6jLks4oaf8';
    const link3 = 'https://drive.google.com/uc?export=download&id=1cRQlKA74i9x4t4gijtmnr6BTHxagDGQO'

    return (
        <div className='container container--sm py-10 text-charcoal-500'>
            <h1 className='text-center text-xl md:text-3xl font-bold'>
                Hướng dẫn đậu ô tô
            </h1>
            <div className="flex gap-3 md:gap-6 items-center justify-center mt-4 md:mt-8">
                <Button icon={<FaMapMarkerAlt />} link={link1} target="_blank">
                    Định vị bãi xe 1
                </Button>
                <Button icon={<FaMapMarkerAlt />} link={link2} target="_blank">
                    Định vị bãi xe 2
                </Button>
            </div>

            <img src={Map} alt="Map" className="w-full h-auto mt-8 md:mt-10" />

            <Button icon={<MdOutlineSaveAlt />} link={link3} target="_blank" className={"w-fit mx-auto mt-8 md:mt-10"}>
                Lưu về máy
            </Button>
        </div>
    );
}
