import { useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import { setIsInPage } from '../redux/appSlice';

import OverlayTopImage from '../assets/overlay-top.svg';
import OverlayBottomImage from '../assets/overlay-bottom.svg';
import OverlayTopImageMobile from '../assets/overlay-top-mobile.svg';
import OverlayBottomImageMobile from '../assets/overlay-bottom-mobile.svg';

export default function Overlay() {
    const { isInPage } = useSelector((state) => state.app);
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(setIsInPage(true));
    }

    const initTopAnimate = {
        rotateX: '0deg',
        scaleY: 1,
    }

    const topAnimate = useMemo(() => {
        if (isInPage) {
            return {
                rotateX: '180deg',
                scaleY: 2,
            }
        }

        return initTopAnimate;
    }, [isInPage])

    const initBottomAnimate = {
        top: 0,
    }

    const bottomAnimate = useMemo(() => {
        if (isInPage) {
            return {
                top: '100%',
            }
        }

        return initBottomAnimate;
    }, [isInPage])
    return (
        <motion.div
            initial={{ opacity: 1, pointerEvents: 'auto', backgroundColor: '#ffffff' }}
            animate={isInPage ? { opacity: 0, pointerEvents: 'none', backgroundColor: '#ffffff' } : { opacity: 1, pointerEvents: 'auto', backgroundColor: '#ffffff' }}
            transition={{ duration: 1, delay: 0.5 }}
            className='fixed top-0 left-0 z-50 w-full h-full cursor-pointer' onClick={handleClick}>
            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                <div className='aspect-[1920/1080] max-md:aspect-[430/932] min-w-[100vw] min-h-[100vh] relative'>
                    <motion.img
                        initial={initBottomAnimate}
                        animate={bottomAnimate}
                        transition={{ type: "tween", duration: 2 }}
                        style={{
                            transformOrigin: 'bottom',
                        }}
                        src={OverlayBottomImage} className='absolute top-0 left-0 w-full h-full object-cover max-md:hidden' />
                    <motion.img
                        initial={initTopAnimate}
                        animate={topAnimate}
                        transition={{ type: "tween", duration: 2 }}
                        style={{
                            transformOrigin: 'top',
                        }}
                        src={OverlayTopImage} className='absolute top-0 left-0 w-full object-cover max-md:hidden' />

                    <motion.img
                        initial={initBottomAnimate}
                        animate={bottomAnimate}
                        transition={{ type: "tween", duration: 2 }}
                        style={{
                            transformOrigin: 'bottom',
                        }}
                        src={OverlayBottomImageMobile} className='absolute top-0 left-0 w-full h-full object-cover max-md:block hidden' />
                    <motion.img
                        initial={initTopAnimate}
                        animate={topAnimate}
                        transition={{ type: "tween", duration: 2 }}
                        style={{
                            transformOrigin: 'top',
                        }}
                        src={OverlayTopImageMobile} className='absolute top-0 left-0 w-full object-cover max-md:block hidden' />

                    <motion.div 
                        initial={{ opacity: 1 }}
                        animate={isInPage ? { opacity: 0 } : { opacity: 1}}
                        transition={{ duration: 1 }}
                        className='absolute max-md:top-1/2 left-1/2 -translate-x-1/2 max-md:-translate-y-1/2 md:bottom-[10%] z-100 text-center text-4xl max-md:text-2xl z-50'>
                        Chạm vào màn hình để mở thiệp
                    </motion.div>
                </div>
            </div>
        </motion.div>
    );
}
