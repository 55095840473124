import { useMemo, useState } from 'react';
import Button from '../elements/Button';
import { MdOutlineCalendarMonth } from "react-icons/md";


export default function CountDownSection() {
    const targetDate = useMemo(() => {
        const targetDate = new Date(2025, 0, 4, 9);
        return targetDate;
    },[])

    const [now, setNow] = useState(new Date());

    const dayLeft = useMemo(() => {
        const diffTime = targetDate.getTime() - now.getTime();
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }, [targetDate, now])

    const hourLeft = useMemo(() => {
        const diffTime = targetDate.getTime() - now.getTime();
        
        const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
        const diffHoursLeft = diffHours - dayLeft * 24;
        return diffHoursLeft;

    }, [targetDate, dayLeft, now])

    const minuteLeft = useMemo(() => {
        const diffTime = targetDate.getTime() - now.getTime();
        const diffMinutes = Math.floor(diffTime / (1000 * 60));
        const diffMinutesLeft = diffMinutes - dayLeft * 24 * 60 - hourLeft * 60;
        return diffMinutesLeft;
    }, [targetDate, dayLeft, hourLeft, now])

    return (
        <div className="container container--sm py-14 md:py-20">
            <div className="flex flex-col gap-4 items-center text-center text-charcoal-500 p-6 bg-blush-100 rounded">
                <div className="text-lg md:text-xl">
                    Cùng đếm ngược đến buổi lễ
                </div>

                <div className="flex items-center gap-8 md:gap-14 mt-4">
                    <div className="flex flex-col items-center gap-2">
                        <div className="text-3xl font-bold tracking-widest md:text-4xl">
                            {dayLeft}
                        </div>
                        <div className="text-lg md:text-xl">
                            Ngày
                        </div>
                    </div>
                    <div className="text-3xl font-bold tracking-widest md:text-4xl">
                        :
                    </div>
                    <div className="flex flex-col items-center gap-2">
                        <div className="text-3xl font-bold tracking-widest md:text-4xl">
                            {hourLeft}
                        </div>
                        <div className="text-lg md:text-xl">
                            Giờ
                        </div>
                    </div>
                    <div className="text-3xl font-bold tracking-widest md:text-4xl">
                        :
                    </div>
                    <div className="flex flex-col items-center gap-2">
                        <div className="text-3xl font-bold tracking-widest md:text-4xl">
                            {minuteLeft}
                        </div>
                        <div className="text-lg md:text-xl">
                            Phút
                        </div>
                    </div>
                </div>

                <div className="mt-4 text-lg md:text-xl">
                    Sự hiện diện của quý khách là niềm vinh hạnh cho gia đình chúng tôi
                </div>

                <Button link="https://drive.google.com/uc?export=download&id=1fPhHbACCJdpJ5o_t6dddcVlA1pW4BC15" target="_blank" icon={<MdOutlineCalendarMonth/>}>
                    Tạo nhắc nhở
                </Button>
            </div>
        </div>
    )
}
