import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';


const initialState = {
    isInPage: false,
    isPlayingMusic: false,
}


export const AppSlice = createSlice({
    name: 'AppSlice',
    initialState,
    reducers: {
        setIsInPage: (state, action) => {
            state.isInPage = action.payload;
        },
        setIsPlayingMusic: (state, action) => {
            state.isPlayingMusic = action.payload;
        },
    }
});

export const { setIsInPage, setIsPlayingMusic } = AppSlice.actions;

export default AppSlice.reducer;