import HeroBanner from './components/HeroBanner';
import ImageSwiper from './components/ImageSwiper';
import CDPlayerWithText from './components/CDPlayerWithText';
import Scrollbar from 'react-smooth-scrollbar';
import { useEffect } from 'react';
import Overlay from './components/Overlay';
import InfoSection from './components/InfoSection';
import SecondInfoSection from './components/SecondInfoSection';
import CountDownSection from './components/CountDownSection';
import HowToSection from './components/HowToSection';
import BottomSwiper from './components/BottomSwiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

function App() {



  return (
    <>
      <div className="App">
        <HeroBanner />
        <ImageSwiper />
        <CDPlayerWithText />
        <InfoSection />
        <SecondInfoSection />
        <CountDownSection />
        <HowToSection />
        <BottomSwiper />
        <div className='h-[200px] w-full'>

        </div>
      </div>
      <Overlay />
    </>
  );
}

export default App;
