import ImageCD from '../assets/CD.png';
import ImageCDPlayer from '../assets/CDPlayer.png';
import ImageStick from '../assets/CDPlayerStick.png';
import { motion } from "motion/react"
import { useSelector } from 'react-redux';

export default function CDPlayer() {
    const { isPlayingMusic } = useSelector(state => state.app)

    return (
        <div className='relative overflow-hidden lg:w-[700px] h-auto'>
            <img className='w-full h-auto' src={ImageCDPlayer} alt="CD Player" />
            <div className='absolute top-[2.1%] left-[3.1%] scale-[92%] h-full w-auto'>
                <motion.img
                    className='h-full w-auto max-h-[100%]'
                    animate={{ rotate: isPlayingMusic ? 360 : 0 }}
                    transition={ isPlayingMusic ? { duration: 10, ease: "linear", repeat: Infinity } : { duration: 1 } }
                    src={ImageCD} alt="CD" />
            </div>
            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full'>
                <motion.img 
                    animate={{ rotate: isPlayingMusic ? 0 : -25 }}
                    className='origin-[90%_15%] w-full h-auto' src={ImageStick} alt="CD Player Stick" />
            </div>
        </div>
    );
}

