// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';

import image1 from '../assets/LD1_9491.png';
import image2 from '../assets/LD1_9507_1.png';
import image3 from '../assets/LD1_9530.png';
import image4 from '../assets/LD1_9665.png';

const images = [image1, image2, image3, image4];


export default function ImageSwiper() {
    return (
        <div className='mt-[15px]'>
            <Swiper
                modules={[Autoplay, Pagination, Navigation]}
                spaceBetween={20}
                breakpoints={{
                    375: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 2.5,
                    },
                    1280: {
                        slidesPerView: 3,
                    }
                }}
                autoplay={{
                    delay: 0,
                    disableOnInteraction: false,
                }}
                speed={3000}
                loop={true}
                centeredSlides={true}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img className='w-full h-full object-cover aspect-[3/2]' src={image} alt={`Image ${index + 1}`} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};