import CDPlayer from './CDPlayer';
import { useSelector, useDispatch } from 'react-redux';
import { setIsPlayingMusic } from '../redux/appSlice';
import { useEffect, useRef } from 'react';
import music from '../assets/music.mp3';
import AndSvg from '../assets/and.svg';
import StringImage from '../assets/string.svg';


export default function CDPlayerWithText() {
    const audio = useRef(null);
    const dispatch = useDispatch();
    const { isPlayingMusic, isInPage } = useSelector(state => state.app)

    useEffect(() => {
        if (isInPage) {
            dispatch(setIsPlayingMusic(true));
        }
    }, [isInPage])

    useEffect(() => {
        if (isPlayingMusic) {
            setTimeout(() => {
                audio.current.play();
            }, 200)
        } else {
            audio.current.pause();
        }
    }, [isPlayingMusic])

    const handleClick = () => {
        dispatch(setIsPlayingMusic(!isPlayingMusic));
    }

    return (
        <div>
            <audio ref={audio} src={music} className='hidden' />
            <div className='mx-auto w-fit mt-[100px] md:mt-[150px] max-md:pl-7 max-md:pr-2 flex flex-col gap-5 text-charcoal-800 font-manrope'>
                <div className="flex flex-row gap-5 items-center justify-between pl-[10px] pr-[20px] md:pl-[20px] md:pr-[30px]">
                    <div className='flex flex-col gap-2'>
                        <div className='text-2xl md:text-3xl font-semibold'>
                            'một đời'
                        </div>
                        <div className='text-sm md:text-base'>
                            14 Casper & Bon Nghiêm (feat. buitruonglinh)
                        </div>
                    </div>
                    <button className='size-10 p-2 md:size-12 md:p-3 rounded-full bg-blush-400 flex flex-row items-center justify-center cursor-pointer' onClick={handleClick}>
                        {
                            !isPlayingMusic ? (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-full">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-full">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
                                </svg>
                            )
                        }
                    </button>
                </div>
                <div className='cursor-pointer' onClick={handleClick}>
                    <CDPlayer />
                </div>
            </div>

            <TextSection />

        </div>
    );
}

function TextSection() {
    const Data = [
        {
            name: 'Nguyễn Phan Bảo Ngọc',
            role: 'Trưởng Nữ',
            familyTitle: 'Nhà Gái',
            father: 'Nguyễn Quốc Bảo',
            mother: 'Phan Hồng Thắm',
            address: 'Phước Yên A - Phú Quới - Long Hồ, Vĩnh Long'
        },
        {
            name: 'Phan Thanh Triều',
            role: 'Út Nam',
            familyTitle: 'Nhà Trai',
            father: 'Phan Văn Thâm',
            mother: 'Nguyễn Thu Nguyệt',
            address: 'Khóm 2 - TT Vũng Liêm - Vũng Liêm - Vĩnh Long'
        }
    ]
    return (
        <div className='container mt-8 md:mt-20'>
            <div className='flex flex-col gap-2 relative'>
                <div className='flex flex-col items-center gap-3 text-center md:flex-row md:gap-6 md:justify-between lg:justify-evenly'>
                    {
                        Data.map((item, index) => (
                            <>
                                <div className='flex flex-col gap-2'>
                                    <div className='font-montecarlo text-4xl lg:text-5xl text-blush-800'>
                                        {item.name}
                                    </div>
                                    <div className='font-beautique text-lg md:text-xl text-charcoal-500'>
                                        {item.role}
                                    </div>
                                    <div className='flex flex-col gap-2 max-md:hidden mt-20 text-blush-800'>
                                        <div className='text-center flex flex-col gap-2 items-center'>
                                            <div className='font-beautique text-lg md:text-xl'>
                                                {item.familyTitle}
                                            </div>
                                            <div className='flex items-center justify-center gap-2'>
                                                <div className='font-beautique text-xs md:text-sm lg:text-base'>
                                                    Ông:
                                                </div>
                                                <div className='font-montecarlo text-lg lg:text-3xl'>
                                                    {item.father}
                                                </div>
                                            </div>
                                            <div className='flex items-center justify-center gap-2'>
                                                <div className='font-beautique text-xs md:text-sm lg:text-base'>
                                                    Bà:
                                                </div>
                                                <div className='font-montecarlo text-lg lg:text-3xl'>
                                                    {item.mother}
                                                </div>
                                            </div>
                                            <div className='text-xs md:text-sm text-charcoal-500'>
                                                {item.address}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    index < Data.length - 1 && (
                                        <div className='md:hidden'>
                                            <img src={AndSvg} alt="and" className='size-6 md:size-10 text-blush-800' />
                                        </div>
                                    )
                                }
                            </>
                        ))
                    }
                </div>

                <div className='md:absolute md:top-[25%] md:left-1/2 md:-translate-x-1/2 w-[190px] mx-auto'>
                    <div className='relative'>
                        <div className='absolute bottom-[300%] left-1/2 -translate-x-1/2 max-md:hidden'>
                            <img src={AndSvg} alt="string" className='size-8 lg:size-10 text-blush-800' />
                        </div>
                        <img src={StringImage} alt="string" className='w-[180px] h-auto object-cover mx-auto my-4' />
                    </div>
                </div>


                <div className='flex flex-row gap-4 justify-between text-blush-800 md:hidden'>
                    {
                        Data.map((item, index) => (
                            <div className='w-fit text-center flex flex-col gap-2 items-center text-blush-800'>
                                <div className='font-beautique text-lg md:text-xl'>
                                    {item.familyTitle}
                                </div>
                                <div className='flex items-center justify-center gap-2'>
                                    <div className='font-beautique text-xs md:text-sm lg:text-base'>
                                        Ông:
                                    </div>
                                    <div className='font-montecarlo text-lg lg:text-2xl'>
                                        {item.father}
                                    </div>
                                </div>
                                <div className='flex items-center justify-center gap-2'>
                                    <div className='font-beautique text-xs md:text-sm lg:text-base'>
                                        Bà:
                                    </div>
                                    <div className='font-montecarlo text-lg lg:text-2xl'>
                                        {item.mother}
                                    </div>
                                </div>
                                <div className='text-xs md:text-sm text-charcoal-500'>
                                    {item.address}
                                </div>
                            </div>
                        ))
                    }
                </div>

            </div>
        </div>
    )
}

