import { Link } from "react-dom";
import { useMemo } from "react";

export default function Button({icon, children, className, link, ...props}) {

    const btnClassName = useMemo(() => {
        return `flex items-center gap-2 md:gap-3 rounded-[16px] bg-blush-400 px-4 
        md:px-6 py-4 text-sm md:text-base font-semibold [&_svg]:size-[20px] 
        ${className}`
    }, [className])

    const inner = useMemo(() => {
        return (
            <>
                {icon && icon}
                <span>{children}</span>
            </>
        )
    }, [icon, children])

    return (
        <>
            {
                link ? (
                    <a href={link} className={btnClassName} {...props}>
                        {inner}
                    </a>
                ) : (
                    <button className={btnClassName} {...props}>
                        {inner}
                    </button>
                )
            }
        </>
    )
}
